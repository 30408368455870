import React from "react";
import {
  SEO,
  PageLayout,
  PaginatedGrid,
  componentIterator,
  Image,
  Review,
  CustomerReviewModal,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessLocation,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <div>
              <div>
                <div className={"yt-container"}>
                  <iframe
                    width={"100%"}
                    height={"700"}
                    src={
                      "https://player.vimeo.com/video/1047498187?background=1"
                    }
                    frameborder={"0"}
                    allow={"autoplay; fullscreen; picture-in-picture"}
                    allowfullscreen={false}
                  />
                  <div className={"hero-logo"} />
                </div>
              </div>
            </div>
          }
          subfooter={false}
          className={"home-page"}
        >
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"home-content"}
          >
            <Grid.Column className={"image-gallery-column"}>
              <PaginatedGrid
                rows={3}
                className={"image-gallery"}
                mobile={{ columns: 2, rows: 4, stackable: false }}
                items={componentIterator({
                  iterator: getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "ImageCollection",
                  }),
                  component: (
                    <Image
                      background={true}
                      style={{ paddingTop: "100%", height: "100%" }}
                    />
                  ),
                  propMap: { src: "__all__" },
                })}
              />
            </Grid.Column>
            <Grid.Column className={"reviews-column"}>
              <PaginatedGrid
                rows={2}
                columns={2}
                className={"reviews"}
                tight={true}
                items={componentIterator({
                  iterator: fishermanBusiness.reviews,
                  component: <Review as={"horizontalItem"} charLimit={200} />,
                  propMap: { key: "_id", author: "author", text: "text" },
                })}
              />
              <div className={"submit-review-button-container"}>
                <CustomerReviewModal
                  businessId={fishermanBusiness._id}
                  locations={allFishermanBusinessLocation.nodes}
                  businessType={fishermanBusiness.type}
                />
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      reviews {
        _id
        author
        text
        link
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessLocation {
      nodes {
        _id
        street
        city
        name
        phoneNumber
        primaryLocation
      }
    }
  }
`;
